body {
  font-weight: 100;
}

.contact-information {
  margin-bottom: 0;

  .icon {
    margin-right: 10px;
  }
}

.icon {
  display: inline-block;
  vertical-align: middle;
}
