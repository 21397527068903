.main {
  padding-bottom: 40px;
  padding-top: 40px;
}

.icon-area {
  .icon-box {
    &:nth-child(3n+4) {
      clear: both;
    }

    h3 {
      display: inline-block;
      font-size: 36px;
      margin-bottom: 40px;
      position: relative;
      line-height: 40px;

      a,
      span.underlined {
        //background: transparent url( "../images/heading-bottom.png" ) repeat left 0px;

        &:hover {
          text-decoration: none;
        }
      }
    }

    .icon-wrap {
      display: block;
      margin-bottom: 40px;
      margin-top: 40px;
    }
  }
}

.odd-section {
  background: #f0f0ec url("../images/odd-section.png") repeat left top;
  padding-bottom: 110px;
  padding-top: 110px;
}

.odd-section,
.home-posts {
  .selected-post {
    .content-wrap {
      background-color: #fff;
      color: #6d6e60;
      padding-bottom: 25px;
      padding-left: 25px;
      padding-right: 25px;
      padding-top: 25px;
      text-align: center;
    }

    h4 {
      font-size: 30px;
      line-height: 40px;
      margin-bottom: 30px;
      min-height: 75px;

      a {
        //background: transparent url( "../images/heading-bottom.png" ) repeat left -6px;

        &:hover {
          text-decoration: none;
        }
      }
    }

    img {
      width: 100%;
      height: auto;
    }
  }
}

.extra-section {
  background: $brand-primary url("../images/extra-section-background.jpg") repeat-x top center;
  padding-bottom: 50px;
  padding-top: 50px;
  margin-top: 50px;

  .testimonials-list {
    color: #fff;
    text-align: center;

    cite {
      display: inline-block;
      margin-left: $grid-gutter-width / 2;
      text-align: left;
      vertical-align: middle;

      span {
        display: block;
      }

      a {
        color: #fff;
        text-decoration: underline;
      }
    }

    .avatar-link {
      display: inline-block;

      img {
        border-radius: 100%;
      }
    }

    blockquote {
      border-left: 0;
      padding: 0;

      p {
        //background: transparent url( "../images/heading-bottom.png" ) repeat left -6px;
        display: inline;
      }

      font-family: "Museo", sans-serif;
      font-weight: 500;
      font-size: 30px;
      line-height: 40px;
    }
  }
}

.main {
  .testimonials-list {
    cite {
      display: inline-block;
      margin-left: $grid-gutter-width / 2;
      text-align: left;
      vertical-align: middle;

      span {
        display: block;
      }

      a {
        text-decoration: underline;
      }
    }

    .testimonials-text {
      margin-bottom: 0.5em;
      margin-top: 2.5em;
    }
  }

  .team-members {
    @extend .row;

    .team-member {
      @extend .col-md-2;

      width: 20%;

      ul {
        line-height: 2;
      }

      .icon {
        margin-right: 10px;
      }

      h3 {
        font-size: 20px;
      }
    }
  }
}

#fefco-catalog {
  .tab-content {
    .tab-pane {
      margin-top: $grid-gutter-width;
    }
  }
}

.page-header {
  h1 {
    font-size: 32px;
  }
}
