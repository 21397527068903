
.flex-container a:hover,
.flex-slider a:hover,
.flex-container a:focus,
.flex-slider a:focus {
  outline: 0;
}

.slides,
.slides>li,
.flex-control-nav,
.flex-direction-nav {
  margin: 0;
  padding: 0;
  list-style: none;
}

.flex-pauseplay span {
  text-transform: capitalize;
}

.flexslider {
  margin: 0;
  padding: 0;
}

.flexslider .slides>li {
  display: none;
  -webkit-backface-visibility: hidden;
}

.flexslider .slides img {
  width: 100%;
  display: block;
}

.flexslider .slides:after {
  content: "\0020";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

html[xmlns] .flexslider .slides {
  display: block;
}

* html .flexslider .slides {
  height: 1%;
}

.no-js .flexslider .slides>li:first-child {
  display: block;
}

.flexslider {
  margin: 0 0 30px;
  border: 0;
  position: relative;
  zoom: 1;
}

.flexslider .slides {
  zoom: 1;
}

.flexslider .slides img {
  height: auto;
}

.flex-viewport {
  max-height: 2000px;
  transition: all 1s ease;
}

.loading .flex-viewport {
  max-height: 300px;
}

.carousel li {
  margin-right: 5px;
}

.flex-direction-nav {
  *height: 0;
}

.flex-direction-nav a {
  text-decoration: none;
  display: block;
  position: absolute;
  top: 50%;
  z-index: 10;
  overflow: hidden;
  opacity: 0;
  cursor: pointer;
  color: rgba(0,0,0,.8);
  transition: all .3s ease-in-out;
}

.flex-direction-nav .flex-prev {
  left: -50px;
}

.flex-direction-nav .flex-next {
  right: -50px;
}

.flexslider:hover .flex-direction-nav .flex-prev {
  opacity: .7;
  left: 10px;
}

.flexslider:hover .flex-direction-nav .flex-prev:hover {
  opacity: 1;
}

.flexslider:hover .flex-direction-nav .flex-next {
  opacity: .7;
  right: 10px;
}

.flexslider:hover .flex-direction-nav .flex-next:hover {
  opacity: 1;
}

.flex-direction-nav .flex-disabled {
  opacity: 0!important;
  filter: alpha(opacity=0);
  cursor: default;
}

.flex-pauseplay a {
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 5px;
  left: 10px;
  opacity: .8;
  z-index: 10;
  overflow: hidden;
  cursor: pointer;
  color: #000;
}

.flex-pauseplay a:before {
  font-family: flexslider-icon;
  font-size: 20px;
  display: inline-block;
  content: '\f004';
}

.flex-pauseplay a:hover {
  opacity: 1;
}

.flex-pauseplay a.flex-play:before {
  content: '\f003';
}

.flex-control-nav {
  width: 100%;
  position: absolute;
  bottom: -40px;
  text-align: center;
}

.flex-control-nav li {
  margin: 0 6px;
  display: inline-block;
  zoom: 1;
  *display: inline;
}

.flex-control-paging li a {
  display: block;
  cursor: pointer;
  text-indent: -9999px;
}

.flex-control-thumbs {
  margin: 5px 0 0;
  position: static;
  overflow: hidden;
}

.flex-control-thumbs li {
  width: 25%;
  float: left;
  margin: 0;
}

.flex-control-thumbs img {
  width: 100%;
  height: auto;
  display: block;
  opacity: .7;
  cursor: pointer;
  transition: all 1s ease;
}

.flex-control-thumbs img:hover {
  opacity: 1;
}

.flex-control-thumbs .flex-active {
  opacity: 1;
  cursor: default;
}
