body {
  padding-top: 170px;
}

.banner {
  .top-section {
    background-color: #f0f0ec;
    color: #afb09f;
    font-weight: 500;
    font-size: 12px;
    padding-bottom: 8px;
    padding-top: 8px;

    a {
      color: #afb09f;
    }

    .language-selection {
      margin-bottom: 0;
      margin-top: 0;

      text-transform: uppercase;

      a {
        display: block;

        img {
          display: inline-block;
        }
      }

      .active {
        color: $brand-primary;

        img {
          opacity: 0.6;
          -webkit-filter: grayscale(100%); /* Chrome, Safari, Opera */
          filter: grayscale(100%);
        }
      }
    }
  }

  background-color: $body-bg;
  position: fixed;

  left: 0;
  right: 0;
  top: 0;
  z-index: 10;
  box-shadow: 0 5px 15px 0 rgba(0,0,0,0.25);

  @include view(phone) {
    position: absolute;
    box-shadow: none;

    .top-section {
      display: block !important;
      height: auto !important;
    }
  }

  &.banner-scroll {
    .brand {
      img {
        height: 60px;

        @include view(phone) {
          height: auto;
        }
      }
    }

    .main-menu {
      margin-top: 35px;

      @include view(phone) {
        margin-top: 50px;
      }
    }
  }

  .brand {
    display: block;
    margin-bottom: 25px;
    margin-top: 25px;

    img {
      transition: 0.5s all linear;
    }
  }

  .main-menu {
    font-family: "Museo", sans-serif;
    font-weight: 500;
    margin-top: 50px;

    transition: 0.5s all linear;

    .nav {
      > li {
        > a {
          border: 1px solid transparent;
          text-transform: uppercase;
        }
      }

      > li.active {
        > a {
          background-color: transparent;
          border: 1px solid $brand-primary;
          color: $brand-primary;
        }
      }
    }

    .dropdown-menu {
      border-radius: 5px;
    }
  }
}

#page-slider {
  overflow: hidden;

  ul.slides {
    li.slide {
      &:first.child {
        display: block;
      }
    }
  }

  /*
  Absolute:

  .slide-description-wrap {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    text-align: center;

    .slide-description {
      left: 0;
      position: absolute;
      right: 0;
      top: 0;

      color: #fff;
      font-family: "Museo", sans-serif;
      font-size: 55px;
      line-height: 65px;
      text-transform: uppercase;

      .btn-default {
        border: 0;
        font-weight: 500;
      }
    }
  }
   */

  .slide-wrap {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  .slide-content-wrap {
    display: table;
    width: 100%;
    height: 100%;
  }

  .slide-description {
    display: table-cell;
    text-align: center;
    vertical-align: middle;

    color: #fff;
    font-family: "Museo", sans-serif;
    font-size: 55px;
    line-height: 65px;
    text-transform: uppercase;

    .btn-default {
      border: 0;
      font-weight: 500;
    }
  }

  .flex-controls {
    position: relative;
    height: 100%;
  }

  .flex-control-paging {
    bottom: $grid-gutter-width / 2;
    left: 0;
    right: 0;
    position: absolute;
    text-align: center;
    z-index: 2;

    li {
      display: inline-block;
      margin-left: 3px;
      margin-right: 3px;

      a {
        border: 3px solid #fff;
        border-radius: 100%;
        cursor: pointer;
        display: block;
        width: 20px;
        height: 20px;
        text-indent: -9999px;

        &.flex-active {
          background-color: #fff;
        }
      }
    }
  }

  .flex-direction-nav {
    left: $grid-gutter-width / 2;
    margin-top: -30px;
    position: absolute;
    right: $grid-gutter-width / 2;
    top: 50%;
    z-index: 2;

    // Right
    li {
      a {
        background: transparent url( '../images/arrow-right.png' ) no-repeat center center;
        display: block;
        text-indent: -9999px;
        height: 100%;
        width: 29px;
        height: 59px;
      }
    }

    // Left
    li:first-child {
      a.flex-prev {
        background: transparent url( '../images/arrow-left.png' ) no-repeat center center;
      }
    }
  }
}
