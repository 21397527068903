.services-area {
  .service-box {
    position: relative;

    @include view(tablet-and-up) {
      &:hover {
        .service-box-color {
          .service-box-content {
            left: 0 - ( $grid-gutter-width / 2 );
            top: 0 - ( $grid-gutter-width / 2 );
            right: 0 - ( $grid-gutter-width / 2 );
            bottom: 0 - ( $grid-gutter-width / 2 );
            opacity: 1;
            padding-left: $grid-gutter-width + 15px;
            padding-right: $grid-gutter-width + 15px;
          }
        }
      }
    }

    @include view(phone) {
      margin-top: 15px !important;
      text-align: left;

      &:after,
      &:before {
        display: none !important;
      }
    }

    .service-box-color {
      border-radius: 100%;
      position: relative;

      .service-box-title {
        color: #fff;
        font-size: 36px;
        margin: 0;
      }

      .service-box-content {
        background-color: #fff;
        border-radius: 100%;
        opacity: 0;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        padding-left: ( $grid-gutter-width / 2 ) + 15px;
        padding-right: ( $grid-gutter-width / 2 ) + 15px;
        position: absolute;
        box-shadow: 0 15px 30px 0 rgba(0,0,0,0.3);

        transition: 0.2s all linear;

        .service-box-content-wrap {
          display: table;
          height: 100%;
          width: 100%;

          .service-box-content-description {
            display: table-cell;
            vertical-align: middle;
          }
        }
      }

      @include view(tablet) {
        margin-left: auto;
        margin-right: auto;
      }

      @include view(phone) {
        background-color: transparent !important;
        width: auto !important;
        height: auto !important;

        .service-box-title {
          color: $headings-color !important;
          line-height: 2 !important;
        }

        .service-box-content {
          opacity: 1;
          padding: 0;
          position: relative;
          top: auto;
          left: auto;
          right: auto;
          bottom: auto;
          box-shadow: none;
        }
      }
    }

    &.service-box-1,
    &.service-box-2,
    &.service-box-3 {
      margin-bottom: 15px;

      @include view(laptop) {
        margin-bottom: $grid-gutter-width;
      }

      @include view(phone) {
        margin-bottom: 15px;
      }

      .service-box-color {
        height: 360px;

        .service-box-title {
          line-height: 360px;
        }

        @include view(laptop) {
          width: 290px;
          height: 290px;

          .service-box-title {
            line-height: 290px;
          }
        }

        @include view(phone) {
          width: auto;
          height: auto;

          .service-box-title {
            line-height: 2;
          }
        }
      }
    }

    &.service-box-4,
    &.service-box-5 {
      .service-box-color {
        margin-left: auto;
        margin-right: auto;
        width: 265px;
        height: 265px;

        .service-box-title {
          line-height: 265px;
        }
      }
    }

    &.service-box-1 {
      .service-box-color {
        background-color: #fcb040;
      }

      &:after {
        background: transparent url("../images/service-step-1-2.png") no-repeat left top;
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: 117px;
        width: 153px;
        height: 36px;

        @include view(laptop) {
          margin-left: 85px;
          top: -15px;
        }
      }
    }

    &.service-box-2 {
      .service-box-color {
        background-color: #f1592a;
      }

      &:after {
        background: transparent url("../images/service-step-1-2.png") no-repeat left -36px;
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: 117px;
        width: 153px;
        height: 36px;

        @include view(laptop) {
          margin-left: 85px;
          top: -15px;
        }
      }
    }

    &.service-box-3 {
      .service-box-color {
        background-color: #f7941e;
      }

      &:after {
        background: transparent url("../images/service-step-1-2.png") no-repeat -153px top;
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -55px;
        margin-top: 5px;
        width: 83px;
        height: 132px;
      }
    }

    &.service-box-4 {
      .service-box-color {
        background-color: #ed217c;
      }
    }

    &.service-box-5 {
      .service-box-color {
        background-color: #92278f;
      }

      &:after {
        background: transparent url("../images/service-step-1-2.png") no-repeat left -72px;
        content: '';
        position: absolute;
        top: 25px;
        left: 50%;
        margin-left: 117px;
        width: 153px;
        height: 36px;

        @include view(laptop) {
          margin-left: 85px;
          top: -15px;
        }
      }
    }

    &.service-box-6 {
      margin-top: 60px;

      .service-box-color {
        background-color: #662d91;
        margin-left: auto;
        margin-right: auto;
        width: 245px;
        height: 245px;

        .service-box-title {
          line-height: 245px;
        }
      }

      &:before {
        background: transparent url("../images/service-step-1-2.png") no-repeat -236px top;
        bottom: 69%;
        content: '';
        position: absolute;
        right: 50%;
        margin-right: 120px;
        width: 83px;
        height: 132px;
      }
    }
  }
}
