/*
SCSS variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: $icon-home-width;
}

The large array-like variables contain all information about a single icon
$icon-home: x y offset_x offset_y width height total_width total_height image_path;

At the bottom of this section, we provide information about the spritesheet itself
$spritesheet: width height image $spritesheet-sprites;
*/
$icon-email-white-name: 'icon-email-white';
$icon-email-white-x: 110px;
$icon-email-white-y: 76px;
$icon-email-white-offset-x: -110px;
$icon-email-white-offset-y: -76px;
$icon-email-white-width: 16px;
$icon-email-white-height: 11px;
$icon-email-white-total-width: 131px;
$icon-email-white-total-height: 129px;
$icon-email-white-image: '../images/sprite.png';
$icon-email-white: (110px, 76px, -110px, -76px, 16px, 11px, 131px, 129px, '../images/sprite.png', 'icon-email-white', );
$icon-email-name: 'icon-email';
$icon-email-x: 0px;
$icon-email-y: 118px;
$icon-email-offset-x: 0px;
$icon-email-offset-y: -118px;
$icon-email-width: 16px;
$icon-email-height: 11px;
$icon-email-total-width: 131px;
$icon-email-total-height: 129px;
$icon-email-image: '../images/sprite.png';
$icon-email: (0px, 118px, 0px, -118px, 16px, 11px, 131px, 129px, '../images/sprite.png', 'icon-email', );
$icon-facebook-name: 'icon-facebook';
$icon-facebook-x: 99px;
$icon-facebook-y: 0px;
$icon-facebook-offset-x: -99px;
$icon-facebook-offset-y: 0px;
$icon-facebook-width: 32px;
$icon-facebook-height: 32px;
$icon-facebook-total-width: 131px;
$icon-facebook-total-height: 129px;
$icon-facebook-image: '../images/sprite.png';
$icon-facebook: (99px, 0px, -99px, 0px, 32px, 32px, 131px, 129px, '../images/sprite.png', 'icon-facebook', );
$icon-footer-logo-name: 'icon-footer-logo';
$icon-footer-logo-x: 0px;
$icon-footer-logo-y: 0px;
$icon-footer-logo-offset-x: 0px;
$icon-footer-logo-offset-y: 0px;
$icon-footer-logo-width: 89px;
$icon-footer-logo-height: 66px;
$icon-footer-logo-total-width: 131px;
$icon-footer-logo-total-height: 129px;
$icon-footer-logo-image: '../images/sprite.png';
$icon-footer-logo: (0px, 0px, 0px, 0px, 89px, 66px, 131px, 129px, '../images/sprite.png', 'icon-footer-logo', );
$icon-googleplus-name: 'icon-googleplus';
$icon-googleplus-x: 0px;
$icon-googleplus-y: 76px;
$icon-googleplus-offset-x: 0px;
$icon-googleplus-offset-y: -76px;
$icon-googleplus-width: 32px;
$icon-googleplus-height: 32px;
$icon-googleplus-total-width: 131px;
$icon-googleplus-total-height: 129px;
$icon-googleplus-image: '../images/sprite.png';
$icon-googleplus: (0px, 76px, 0px, -76px, 32px, 32px, 131px, 129px, '../images/sprite.png', 'icon-googleplus', );
$icon-linkedin-name: 'icon-linkedin';
$icon-linkedin-x: 42px;
$icon-linkedin-y: 76px;
$icon-linkedin-offset-x: -42px;
$icon-linkedin-offset-y: -76px;
$icon-linkedin-width: 32px;
$icon-linkedin-height: 32px;
$icon-linkedin-total-width: 131px;
$icon-linkedin-total-height: 129px;
$icon-linkedin-image: '../images/sprite.png';
$icon-linkedin: (42px, 76px, -42px, -76px, 32px, 32px, 131px, 129px, '../images/sprite.png', 'icon-linkedin', );
$icon-phone-white-name: 'icon-phone-white';
$icon-phone-white-x: 99px;
$icon-phone-white-y: 42px;
$icon-phone-white-offset-x: -99px;
$icon-phone-white-offset-y: -42px;
$icon-phone-white-width: 16px;
$icon-phone-white-height: 16px;
$icon-phone-white-total-width: 131px;
$icon-phone-white-total-height: 129px;
$icon-phone-white-image: '../images/sprite.png';
$icon-phone-white: (99px, 42px, -99px, -42px, 16px, 16px, 131px, 129px, '../images/sprite.png', 'icon-phone-white', );
$icon-phone-name: 'icon-phone';
$icon-phone-x: 84px;
$icon-phone-y: 76px;
$icon-phone-offset-x: -84px;
$icon-phone-offset-y: -76px;
$icon-phone-width: 16px;
$icon-phone-height: 16px;
$icon-phone-total-width: 131px;
$icon-phone-total-height: 129px;
$icon-phone-image: '../images/sprite.png';
$icon-phone: (84px, 76px, -84px, -76px, 16px, 16px, 131px, 129px, '../images/sprite.png', 'icon-phone', );
$spritesheet-width: 131px;
$spritesheet-height: 129px;
$spritesheet-image: '../images/sprite.png';
$spritesheet-sprites: ($icon-email-white, $icon-email, $icon-facebook, $icon-footer-logo, $icon-googleplus, $icon-linkedin, $icon-phone-white, $icon-phone, );
$spritesheet: (131px, 129px, '../images/sprite.png', $spritesheet-sprites, );

/*
The provided mixins are intended to be used with the array-like variables

.icon-home {
  @include sprite-width($icon-home);
}

.icon-email {
  @include sprite($icon-email);
}
*/
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

/*
The `sprites` mixin generates identical output to the CSS template
  but can be overridden inside of SCSS

@include sprites($spritesheet-sprites);
*/
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
