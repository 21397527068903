@mixin view($point) {
  @if $point == desktop {
    @media (min-width: $screen-lg-min) { @content; }
  }
  @else if $point == laptop {
    @media (max-width: ( $screen-lg-min - 1px ) ) { @content; }
  }
  @else if $point == laptop-and-up {
    @media (min-width: ( $screen-lg-min ) ) { @content; }
  }
  @else if $point == tablet {
    @media (max-width: ( $screen-md-min - 1px ) )  { @content; }
  }
  @else if $point == only-tablet {
    @media (min-width: ( $screen-sm-min ) ) and (max-width: ( $screen-md-min - 1px ) )  { @content; }
  }
  @else if $point == tablet-and-up {
    @media (min-width: ( $screen-sm-min ) ) { @content; }
  }
  @else if $point == phone {
    @media (max-width: ( $screen-sm-min - 1px ) )  { @content; }
  }
  @else if $point == only-phone {
    @media (max-width: ( $screen-sm-min - 1px ) )  { @content; }
  }
  @else if $point == medium-phone {
    @media (max-width: 559px)  { @content; }
  }
  @else if $point == small-phone {
    @media (max-width: 419px)  { @content; }
  }
  @else if $point == mini-phone {
    @media (max-width: 359px)  { @content; }
  }
}
