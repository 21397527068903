@font-face {
  font-family: 'Museo';
  src: url('../fonts/museo300-regular-webfont.eot');
  src: url('../fonts/museo300-regular-webfont.eot?#iefix') format('embedded-opentype'),
     url('../fonts/museo300-regular-webfont.woff2') format('woff2'),
     url('../fonts/museo300-regular-webfont.woff') format('woff'),
     url('../fonts/museo300-regular-webfont.ttf') format('truetype'),
     url('../fonts/museo300-regular-webfont.svg#museo300') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Museo';
  src: url('../fonts/museo500-regular-webfont.eot');
  src: url('../fonts/museo500-regular-webfont.eot?#iefix') format('embedded-opentype'),
     url('../fonts/museo500-regular-webfont.woff2') format('woff2'),
     url('../fonts/museo500-regular-webfont.woff') format('woff'),
     url('../fonts/museo500-regular-webfont.ttf') format('truetype'),
     url('../fonts/museo500-regular-webfont.svg#museo500') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Museo';
  src: url('../fonts/museo700-regular-webfont.eot');
  src: url('../fonts/museo700-regular-webfont.eot?#iefix') format('embedded-opentype'),
     url('../fonts/museo700-regular-webfont.woff2') format('woff2'),
     url('../fonts/museo700-regular-webfont.woff') format('woff'),
     url('../fonts/museo700-regular-webfont.ttf') format('truetype'),
     url('../fonts/museo700-regular-webfont.svg#museo700') format('svg');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('../fonts/museosans_100-webfont.eot');
  src: url('../fonts/museosans_100-webfont.eot?#iefix') format('embedded-opentype'),
     url('../fonts/museosans_100-webfont.woff2') format('woff2'),
     url('../fonts/museosans_100-webfont.woff') format('woff'),
     url('../fonts/museosans_100-webfont.ttf') format('truetype'),
     url('../fonts/museosans_100-webfont.svg#museo_sans100') format('svg');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('../fonts/museosans_500-webfont.eot');
  src: url('../fonts/museosans_500-webfont.eot?#iefix') format('embedded-opentype'),
     url('../fonts/museosans_500-webfont.woff2') format('woff2'),
     url('../fonts/museosans_500-webfont.woff') format('woff'),
     url('../fonts/museosans_500-webfont.ttf') format('truetype'),
     url('../fonts/museosans_500-webfont.svg#museo_sans500') format('svg');
  font-weight: 500;
  font-style: normal;
}
