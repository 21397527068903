.content-info {
  background-color: $brand-primary;
  line-height: 32px;

  ul {
    margin-bottom: 0;
  }

  .language-selection {
    img {
      display: inline-block;
    }

    a {
      display: block;
    }

    .active {
      img {
        opacity: 0.6;
        -webkit-filter: grayscale(100%); /* Chrome, Safari, Opera */
        filter: grayscale(100%);
      }
    }
  }

  .footer-menu {
    a {
      font-weight: 500;
      color: #f0f0ec;
      font-size: 12px;
      text-transform: uppercase;
    }
  }

  .copyright {
    background-color: $brand-secondary;
    color: #afb09f;
    font-family: "Museo Sans", sans-serif;
    font-size: 12px;
    padding-bottom: 10px;

    .icon {
      margin-top: 30px;
      margin-bottom: 40px;
    }
  }
}
