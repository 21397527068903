// Glyphicons font path
$icon-font-path:        "../fonts/";

// Fonts
$font-family-sans-serif:  "Museo Sans", Helvetica, Arial, sans-serif;
$font-size-base:          15px;

// Colors
$brand-primary:         #92278f;
$brand-secondary:       #662d91;

// Headings
$headings-font-family:   "Museo", sans-serif;
$headings-font-weight:   300;
$headings-color:         $brand-primary;

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Borders
$border-radius-base:    20px;
$border-radius-large:   25px;
$border-radius-small:   15px;

// Buttons
$btn-default-color:     $brand-primary;

// Nav
$nav-link-padding:        9px 25px 7px;
$nav-pills-border-radius: 25px;

// Headings sizes
$font-size-h1:            26px;
$font-size-h2:            22px;
$font-size-h3:            18px;
$font-size-h4:            16px;
$font-size-h5:            $font-size-base;
$font-size-h6:            14px;
